import React from "react";
import { Button } from "rsuite";

require("./Contact.scss");

class Contact extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="contact">
          <div className="contactform container content">

            {/* <form name="contact" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="contact" />

  <p>
    <label>Your Name: <input type="text" name="name" /></label>   
  </p>
  <p>
    <label>Your Email: <input type="email" name="email" /></label>
  </p>
  <p>
    <label>Message: <textarea name="message"></textarea></label>
  </p>
  <p>
    <button type="submit">Send</button>
  </p>
</form> */}

            <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="bot-field" />
              <div className="field">
                <label className="label">Name</label>
                <div className="control">
                  <input name="name" className="input" type="text" placeholder="Name" />
                </div>
              </div>


              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input name="email" className="input" type="email" placeholder="Email" />
                  <span className="icon is-small is-right">
                    <i className="fas fa-exclamation-triangle"></i>
                  </span>
                </div>
              </div>

              <div className="field">
                <label className="label">Subject</label>
                <div className="control">
                  <div className="select">
                    <select name="subject">
                      <option value="generalEnquiry">General enquiry</option>
                      <option value="webAudit">Website audit</option>
                      <option value="trainingRequest">Training request</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Message</label>
                <div className="control">
                  <textarea name="message" className="textarea" placeholder="Textarea"></textarea>
                </div>
              </div>

              <div className="field">
                Would you like to receive tips and tricks for succeeding online?
                <div className="control">
                  <label className="radio">
                    <input type="radio" name="tricks" />
                    Yes
                  </label>
                  <label className="radio">
                    <input type="radio" name="tricks" />
                    No
                  </label>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <Button type="submit" className="is-link">Submit</Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;
